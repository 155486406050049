import Vue from 'vue';
import PrismicVue from '@prismicio/vue';
import { PRISMIC_ENDPOINT } from '@/config';
import linkResolver from '@/prismic.linkresolver';

export default function setUpPrismic() {
    Vue.use(PrismicVue, {
        endpoint: PRISMIC_ENDPOINT,
        linkResolver,
    });

    const PrismicRichText: any = Vue.component('PrismicRichText');

    PrismicRichText.mixin({
        mounted() {
            this.addDataAttribute();
        },
        updated() {
            this.addDataAttribute();
        },
        methods: {
            addDataAttribute(this: any): void {
                if (this.$el && this.$el.dataset) {
                    // We'll use this to obscure CMS content when looking for strings to translate.
                    this.$el.dataset.isPrismicRichText = true;
                }
            },
        },
    });
}
