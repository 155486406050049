import * as Sentry from '@sentry/vue';
import Vue from '@/vueTyped';
import { SENTRY_DSN, SENTRY_ENVIRONMENT } from '@/config';
import { BrowserTracing } from '@sentry/tracing';
import { Device } from '@capacitor/device';
import router from '@/router';

export default function setUpSentry() {
    Sentry.init({
        Vue,
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
    });

    Device.getInfo().then(deviceInfo => {
        Sentry.setTag('platform', deviceInfo.platform);
    });

    const consoleError = console.error.bind(console);
    console.error = (error: unknown) => {
        Sentry.withScope(scope => {
            scope.setTag('source', 'console.error');
            Sentry.captureException(error);
        });
        consoleError(error);
    };
}
