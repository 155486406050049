
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'BasePhotoInput',
    props: {
        capture: {
            type: String,
            default: null,
        },
        inputLabel: {
            type: String,
            default: ''
        },
        inputAriaHidden: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        handleClick() {
            // TODO: Refactor this (and the PhotoSelection component).
            const useFileInput = true; // !['ios', 'android'].includes(this.$store.state.platform);
            if (useFileInput) {
                // @ts-ignore
                this.$refs.fileInput.click();
            } else {
                // this.$emit('click');
            }
        },
        async handleFileInputChange() {
            const file = (this.$refs.fileInput as HTMLInputElement).files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    this.$emit('loaded', {
                        webPath: reader.result,
                        file,
                    });
                }, false);
                reader.readAsDataURL(file);
            }
        },
    },
});
