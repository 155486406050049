import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import Vue from 'vue';
import SocialSharing from 'vue-social-sharing';
import VueMeta from 'vue-meta'

function globalizeBaseComponents() {
    const requireComponent = require.context(
        // The relative path of the components folder
        '../components',
        // Whether or not to look in subfolders
        false,
        // The regular expression used to match base component filenames
        /Base[A-Z]\w+\.(vue|js)$/
    );

    requireComponent.keys().forEach(fileName => {
        // Get component config
        const componentConfig = requireComponent(fileName);
        // Get PascalCase name of component
        const nameFragments = fileName.split('/');
        let name;
        if (nameFragments.length) {
            name = nameFragments.pop();
            if (name) {
                name = name.replace(/\.\w+$/, '');
                const componentName = upperFirst(camelCase(name));
                // Register component globally
                Vue.component(
                    componentName,
                    // Look for the component options on `.default`, which will
                    // exist if the component was exported with `export default`,
                    // otherwise fall back to module's root.
                    componentConfig.default || componentConfig,
                );
            }
        }
    });
}

export default function setUpVue() {
    Vue.config.productionTip = false;
    Vue.config.devtools = true;
    Vue.config.performance = true;

    Vue.use(SocialSharing);

    Vue.use(VueMeta, {
        keyName: 'metaInfo',
        attribute: 'data-vue-meta',
        ssrAttribute: 'data-vue-meta-server-rendered',
        tagIDKeyName: 'vmid',
        refreshOnceOnNavigation: true,
    });

    globalizeBaseComponents();
}
