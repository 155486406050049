
import Vue from '@/vueTyped';
import MarkdownOutput from './MarkdownOutput.vue';

export default Vue.extend({
    components: { MarkdownOutput },

    data() {
        return {
            resetting: false,
            defaultTimeout: 12000,
        };
    },

    computed: {
        alerts(): any[] {
            return this.$store.state.alerts;
        },

        alert(): any {
            return this.alerts[0];
        },
    },

    methods: {
        async dismissFirst() {
            this.resetting = true;
            this.$store.dispatch('dismissAlert');
            await this.$nextTick();
            this.resetting = false;
        }
    }
});
