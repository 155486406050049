
import Vue from '@/vueTyped';

const SCROLLBAR_CLEARANCE = '16px';

export default Vue.extend({
    name: 'CopyText',
    props: {
        text: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            lineHeight: '1em',
        };
    },

    computed: {
        rows(): Number {
            if (this.$attrs.rows !== undefined) {
                return parseFloat(this.$attrs.rows);
            } else {
                return this.text.split('\n').length;
            }
        },

        height(): String {
            return `calc(${this.lineHeight} * ${this.rows} + var(--spacing-2) * 2 + ${SCROLLBAR_CLEARANCE})`;
        }
    },

    mounted() {
        this.lineHeight = getComputedStyle(this.$el).lineHeight;
    },

    methods: {
        selectAll() {
            (this.$el as HTMLTextAreaElement).select();
        }
    },
});
