import Vue from 'vue';
import moment from 'moment';
import { API_HOST } from '@/config';
import { shortAddressFromComponents } from '@/util.location';
import { Post, Photo } from '@/types';
import { extend as extendVeeValidate } from 'vee-validate/dist/vee-validate.full.esm';

export default function setUpVueExtras() {
    Vue.filter('formatDate', (value: string, formatString: string) => {
        if (value) {
            return moment(String(value)).format(formatString || 'MM/DD/YYYY');
        }
    });

    Vue.filter('formatDateAndTime', (value: string, formatString: string) => {
        if (value) {
            return moment(String(value)).format(formatString || 'MM/DD/YYYY h:mm A');
        }
    });

    Vue.filter('percent', (value: number) => {
        return `${(value * 100).toFixed(2)}%`;
    });

    Vue.filter('fromNowDate', (value: string) => {
        if (value) {
            return moment(String(value)).fromNow();
        }
    });

    Vue.filter('hyphenate', (value: string) => {
        return value.replace(/\s+/g, '-').toLowerCase();
    });

    Vue.filter('pluralize', (single: string, count: number, plural: string = `${single}s`) => {
        return count === 1 ? single : plural;
    });

    Vue.filter('formatImage', (img: Photo, width: number, height = width, scale = window.devicePixelRatio) => {
        // NOTE: Looks like `w_` is the only dimension the back end respects right now?
        const niceWidth = width * scale;
        const niceHeight = height * scale;

        // We'll make image requests in a common multiple, since every user requesting a size specific to their viewport means
        // the back end has to generate and cache all those different sizes. Now it can generate and cache multiples of this.
        const WIDTH_MULTIPLE = 80;
        const widthByMultiple = (niceWidth + WIDTH_MULTIPLE - 1) - ((niceWidth + WIDTH_MULTIPLE - 1) % WIDTH_MULTIPLE);

        let requestWidth = Math.round(widthByMultiple);
        let requestHeight = Math.round(niceHeight * (requestWidth / niceWidth));

        const MAX_SIZE = WIDTH_MULTIPLE * 24; // 1920

        if (requestWidth > MAX_SIZE) {
            requestHeight /= requestWidth / MAX_SIZE;
            requestWidth = MAX_SIZE;
        }

        if (requestHeight > MAX_SIZE) {
            requestWidth /= requestHeight / MAX_SIZE;
            requestHeight = MAX_SIZE;
        }

        return `${API_HOST}/api/v1/${img.dirPath}/w_${Math.floor(requestWidth)},h_${Math.floor(requestHeight)},c_fill,a_exif/${img.id}.${img.format}`;
    });

    Vue.filter('postShortAddress', (post: Post) => {
        if (!post.addressComponents) {
            return post.shortAddress || '';
        }
        return shortAddressFromComponents(post.addressComponents);
    });

    extendVeeValidate('one_capital', {
        validate(value: string) {
            return Boolean(value.match(/[A-Z]/)) || 'The {_field_} field must contain a capital letter';
        }
    });

    extendVeeValidate('one_number', {
        validate(value: string) {
            return Boolean(value.match(/\d/)) || 'The {_field_} field must contain a number';
        }
    });

    extendVeeValidate('phone_number', {
        validate(value: string) {
        const digits = value.match(/(\d)/g) ?? [];
        const ok = digits.length >= 8; // Based on https://stackoverflow.com/a/17734434
        return ok || 'This field must contain a phone number';
        },
    });
}
