
import Vue from '@/vueTyped';
import TheLanguageSelect from '@/components/TheLanguageSelect.vue';

export default Vue.extend({
    name: 'LanguageCallout',

    components: {
        TheLanguageSelect,
    },

    props: {
        actualClass: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            shouldCallOutLanguageSelect: location.search.includes('show-language-callout') || !localStorage.languageCalloutSeen,
        };
    },

    watch: {
        '$i18n.locale': 'dismissLanguageCallout',
    },

    methods: {
        dismissLanguageCallout() {
            if (this.shouldCallOutLanguageSelect) {
                localStorage.languageCalloutSeen = new Date().toString();
                this.shouldCallOutLanguageSelect = false;
            }
        },
    },
});
