
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: {
            type: String,
            default: 'div',
        },
    },
});
