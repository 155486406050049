
import Vue from '@/vueTyped';
import TransitionExpand from '@/components/TransitionExpand.vue';

export default Vue.extend({
    components: {
        TransitionExpand,
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },

        icon: {
            type: String,
            default: 'info'
        }
    },
});
