import { App } from '@capacitor/app';
import { authTokenExpiresBefore } from '@/api-client';
import store from '@/store';

export default function addCheckForExpiredAuthToken() {
    App.addListener('appStateChange', async ({ isActive }) => {
        if (!isActive) {
            return;
        }

        const twentyFourHoursFromNow = new Date(Date.now() + 24 * 60 * 60 * 1000);
        const tokenExpiredAlready = await authTokenExpiresBefore(new Date());
        const tokenExpiresSoon = !tokenExpiredAlready && await authTokenExpiresBefore(twentyFourHoursFromNow);

        if (tokenExpiresSoon) {
            // App activation is probably an okay time to silently throw out a token that will expire soon.
            // Beats having it expire while they're using the app and prompting them to sign back in.
            // TODO: Refresh the auth token before it expires.
            store.dispatch('accountLogOut');
        } else {
            store.dispatch('checkIfSignedIn');
        }
    });
}
