
import Vue from '@/vueTyped';
import MobileLayout from '@/layouts/Mobile.layout.vue';
import WebLayout from '@/layouts/Web.layout.vue';
import LoadingLayout from '@/layouts/Loading.layout.vue';
import SurveyModal from '@/components/Survey/Modal.vue';
import TheErrors from '@/components/TheErrors.vue';
import TheShareDialog from '@/components/TheShareDialog.vue';
import smoothscroll from 'smoothscroll-polyfill';
import { RootState } from './types';
import { API_HOST_OVERRIDE, BUILD_ENVIRONMENT, ENVIRONMENT_IN_USE } from './config';

export default Vue.extend({
  components: {
    SurveyModal,
    TheErrors,
    TheShareDialog,
  },
  computed: {
    platform(): string | null {
      return (this.$store.state as RootState).platform;
    },
    layout(): any {
      // TODO: "/" renders before the actual current route, so `LoadingLayout` flashes before the page. Why?
      if (this.$route.meta?.ignoreAppLayout) {
          return 'div';
      } else if (this.platform === null) {
        return LoadingLayout;
      } else {
          const smallScreen = this.$vuetify.breakpoint.xsOnly;
          const isNativeApp = ['ios', 'android'].includes(this.platform);
        if (smallScreen || isNativeApp) {
          return MobileLayout;
        } else {
          return WebLayout;
        }
      }
    },
    environmentOverride(): { is: string, shouldBe: string } | null {
        return ENVIRONMENT_IN_USE === BUILD_ENVIRONMENT ? null : {
            is: ENVIRONMENT_IN_USE,
            shouldBe: BUILD_ENVIRONMENT,
        };
    },
    apiHostOverride(): typeof API_HOST_OVERRIDE {
        return API_HOST_OVERRIDE;
    },
  },
  mounted() {
    // $vuetify.goTo doesn't work horizontally.
    // https://github.com/vuetifyjs/vuetify/issues/5732
    smoothscroll.polyfill();
  },

  methods: {
      removeQueryParam(key: string) {
          const url = new URL(location.href);
          url.searchParams.delete(key);
          location.replace(url.href);
      }
  },
});
