let currentActiveElement: HTMLElement | null = null;

document?.addEventListener('focus', function(event: FocusEvent) {
    currentActiveElement = event.target as HTMLElement;
}, true);

export default {
    data() {
        return {
            activeElementAtOpen: null as HTMLElement | null,
        };
    },

    watch: {
        value: {
            immediate: true,
            async handler(value: boolean) {
                if (value) {
                    this.activeElementAtOpen = currentActiveElement;
                } else {
                    const { activeElementAtOpen } = this;
                    await this.$nextTick();
                    activeElementAtOpen?.focus();
                }
            },
        } as any,
    },
};
