
import Vue from '@/vueTyped';
import moment from 'moment';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@/config';
import { trackAppLinkClick } from '@/tracking';

export default Vue.extend({
    name: 'AppBanner',

    data() {
        const dismissedDate = localStorage.getItem('app-banner-dismissed');
        const deviceType = navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)?.[0];

        return {
            dismissed: Boolean(dismissedDate) && moment().diff(dismissedDate, 'weeks') < 6,
            devicePlatform: deviceType && (deviceType.toLowerCase() === 'android' ? 'ANDROID' : 'IOS'),
        };
    },

    computed: {
        appPlatform() {
            return this.$store.state.platform;
        },

        shouldDisplay(): boolean {
            return Boolean(!this.dismissed && this.appPlatform === 'web' && this.devicePlatform);
        },

        storeName(): string {
            return this.devicePlatform === 'ANDROID' ? 'Google Play' : 'the App Store';
        },

        appLink(): string {
            return this.devicePlatform === 'ANDROID' ? ANDROID_APP_LINK : IOS_APP_LINK;
        },
    },

    methods: {
        trackClick() {
            if (this.devicePlatform) {
                trackAppLinkClick(this.devicePlatform.toLowerCase());
            }
        },
        dismiss() {
            localStorage.setItem('app-banner-dismissed', new Date().toISOString());
            this.dismissed = true;
        },
    }
});
