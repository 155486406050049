
import Vue from '@/vueTyped';
import { messages, sourceLocale } from '@/i18n'
import { PrismicDocument } from '@/types';

const cache: { [key: string]: PrismicDocument } = JSON.parse(window.localStorage.cmsContent ?? '{}');
const recentlyFetchedKeys: string[] = [];

export default Vue.extend({
    name: 'CmsContent',

    props: {
        tag: {
            type: String,
            default: 'div',
        },

        type: {
            type: String,
            required: true,
        },

        uid: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            loading: false,
            document: null as PrismicDocument | null,
            notFound: false,
        };
    },

    watch: {
        '$i18n.locale': {
            immediate: true,
            handler() {
                this.fetch();
            }
        },

        type() {
            this.fetch();
        },

        uid() {
            this.fetch();
        },
    },

    methods: {
        async fetch(this: any, locale?: string): Promise<void> {
            this.loading = true;
            this.notFound = false;

            if (!locale) {
                locale = this.$i18n.locale as string;
            }

            const lang = messages[locale].prismicLocale;

            const key = `${lang}:${this.type}:${this.uid ?? '{SINGLE}'}`;

            this.document = cache[key] as PrismicDocument;

            // If it's from localStorage, we'll return it, but also fetch fresh content in the background.
            // Note that this can be `loading` and still have a `document`.
            const fromLocalStorage = this.document && !recentlyFetchedKeys.includes(key);

            if (this.document === undefined || fromLocalStorage) {
                if (this.uid) {
                    this.document = await this.$prismic.client.getByUID(this.type, this.uid, { lang });
                } else {
                    this.document = await this.$prismic.client.getSingle(this.type, { lang });
                }

                if (this.document) {
                    // We won't load this content again until the page refreshes.
                    recentlyFetchedKeys.push(key);
                    cache[key] = this.document;
                    window.localStorage.cmsContent = JSON.stringify(cache);
                } else {
                    if (locale === sourceLocale) {
                        this.notFound = true;
                        this.$emit('not-found');
                    } else {
                        console.warn(`CMS content ${key} not found in ${lang}, falling back to ${sourceLocale}...`);
                        return this.fetch(sourceLocale);
                    }
                }
            }

            this.loading = false;
            this.$emit('load', this.document);
        },
    },
});
