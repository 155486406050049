
import Vue from '@/vueTyped';
import CmsContent from '@/components/CmsContent.vue';
import LanguageCallout from '@/components/LanguageCallout.vue';
import SlippyCarousel from '@/components/SlippyCarousel.vue';

const ONBOARDING_LOCALSTORAGE_KEY = 'onboarding-seen';

export default Vue.extend({
    name: 'Onboarding',

    components: {
        CmsContent,
        LanguageCallout,
        SlippyCarousel,
    },

    data() {
        return {
            internalSeenDate: null as Date | null,
            inIntro: true,
        };
    },

    computed: {
        showing: {
            get(): boolean {
                return this.internalSeenDate === null;
            },

            set(showing: boolean) {
                if (showing) {
                    try {
                        localStorage.removeItem(ONBOARDING_LOCALSTORAGE_KEY);
                    } finally {
                        this.internalSeenDate = null;
                    }
                } else {
                    const now = new Date();

                    try {
                        localStorage.setItem(ONBOARDING_LOCALSTORAGE_KEY, now.toISOString());
                    } finally {
                        this.internalSeenDate = now;
                    }
                }
            }
        }
    },

    mounted() {
        const seenTimestamp = localStorage.getItem(ONBOARDING_LOCALSTORAGE_KEY);

        if (seenTimestamp) {
            this.internalSeenDate = new Date(seenTimestamp);
        }
    },
});
