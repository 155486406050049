
import Vue from '@/vueTyped';
import { messages, devLocale } from '@/i18n';
import { trackLocale } from '@/tracking';

export default Vue.extend({
    name: 'TheLanguageSelect',
    data() {
        return {
            messages,
            devLocale,
            dialog: false,
            showingDevLocale: false,
            selection: this.$i18n.locale,
        };
    },

    watch: {
        '$i18n.locale': {
            immediate: true,
            handler(locale) {
                // TODO: There's probably a better place for this.
                document.documentElement.lang = locale;
                document.documentElement.classList[locale === devLocale ? 'add' : 'remove']('dev-locale-active');
            },
        }
    },

    methods: {
        updateLang() {
            this.dialog = false;

            if (this.selection === this.$i18n.locale) {
                return;
            }

            this.$i18n.locale = this.selection;
            trackLocale(this.$i18n.locale, true);
        },
    }
});
