import { Device } from '@capacitor/device';
import { Deploy } from 'cordova-plugin-ionic';
// import { App } from '@capacitor/app';
// import { codePush, InstallMode } from 'dwimcore-capacitor-codepush';

export default async function addCheckToInstallBackgroundUpdates() {
    const deviceInfo = await Device.getInfo();

    if (deviceInfo.platform === 'web') {
        return;
    }

    // TODO: I had a hell of a time un-loading previous Ionic Appflow updates.
    // In the near future, we can undo the changes from the commit that adds this.

    const versions = await Deploy.getAvailableVersions();

    if (versions.length !== 0) {
        for (const version of versions) {
            Deploy.deleteVersionById(version.versionId);
        }

        await Deploy.reloadApp();
    }

    // App.addListener('appStateChange', ({ isActive }) => {
    //     try {
    //         if (isActive) {
    //             codePush.sync({
    //                 installMode: InstallMode.ON_NEXT_RESUME
    //             }, (progress) => {
    //                 if (progress && progress.receivedBytes / progress.totalBytes >= 1) {
    //                     codePush.notifyApplicationReady();
    //                 }
    //             });
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // });
}
