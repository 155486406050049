
import Vue from '@/vueTyped';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
    name: 'LoadingLayout',
    components: {
        LoadingIndicator,
    },
});

