
import Vue from '@/vueTyped';
import { CurrentUser, LngLat, MBAddressObj } from '@/types';
import DeviationDescription from './DeviationDescription.vue';
import ModalContent from './ModalContent.vue';
import OverlayModal from '@/layouts/OverlayModal.vue';
import PromptBanner from '../PromptBanner.vue';
import moment from 'moment';
import { WEATHER_API_HOST } from '@/config';
import { trackHistoricalWeatherPromptClickThrough, trackHistoricalWeatherPromptDismiss, trackHistoricalWeatherPromptOpen, trackHistoricalWeatherPromptBannerReveal } from '@/tracking';
import { geocode, reverseGeocode } from '@/util.mapbox';
import tidyWeatherAnalysis, { WeatherAnalyticsResponse } from '@/util.tidy-weather-analysis';

export default Vue.extend({
    components: {
        DeviationDescription,
        ModalContent,
        OverlayModal,
        PromptBanner,
    },

    provide(this: any) {
        return {
            promptRoot: this,
        };
    },

    data() {
        return {
            timestampOverride: '', // e.g. 'YYYY-MM-DD'
            locationOverride: null as MBAddressObj | null, // Mapbox location object
            analysis: null as any,
            dialogOpen: false,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        coords(): LngLat | null {
            if (this.locationOverride) {
                const [lng, lat] = this.locationOverride.center;
                return [lng, lat];
            } else {
                const { lng, lat } = this.currentUser ?? {};
                if (typeof lng === 'number' && typeof lat === 'number') {
                    return [lng, lat];
                } else {
                    return null;
                }
            }
        },

        dismissedToday(): boolean {
            const lastDismissed = new Date(this.currentUser?.userSettings?.historicalWeatherPromptDismissed || 0);
            const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));
            return lastDismissed >= startOfToday;
        },

        units(): 'metric' | 'us' {
            const { preferredSystemOfMeasure } = this.currentUser?.userSettings ?? {};
            return preferredSystemOfMeasure === 'METRIC' ? 'metric' : 'us';
        },

        apiEndpoint(): string | null {
            if (this.coords === null || this.dismissedToday) {
                return null;
            }
            const now = moment(this.timestampOverride || new Date().setSeconds(0, 0)).format();
            return `${WEATHER_API_HOST}/temperature_today_vs_week_daily_history/${[...this.coords].reverse()}/${now}?unit_system=${this.units}`;
        },

        shouldShow(): boolean {
            return Boolean(this.analysis && (this.analysis?.highDeviation > 1 || this.analysis?.lowDeviation > 1));
        },
    },

    watch: {
        '$store.state.account.currentUser'(currentUser, currentUserWas) {
            // When a user signs in with an override,
            // clear the last time they dismissed the prompt
            // to make demoing and debugging easier.
            if (!currentUser) return;
            if (currentUser.id === currentUserWas?.id) return;
            if (this.timestampOverride || this.locationOverride) {
                this.$store.dispatch('updateSettings', { historicalWeatherPromptDismissed: '' });
            }
        },

        apiEndpoint(apiEndpoint) {
            this.analysis = null;
            if (apiEndpoint) {
                this.fetchHistoricalWeatherAnalysis();
            }
        },

        shouldShow(shouldShow) {
            if (shouldShow) {
                trackHistoricalWeatherPromptBannerReveal(this.analysis);
            }
        },

        dialogOpen(dialogOpen) {
            if (dialogOpen) {
                trackHistoricalWeatherPromptOpen(this.analysis);
            }
        },
    },

    async mounted() {
        // TODO: Why doesn't this.$route know about its query yet?
        const searchParams = new URLSearchParams(location.search);
        const timestampOverrideParam = searchParams.get('override-historical-weather-timestamp');
        const locationOverrideParam = searchParams.get('override-historical-weather-location');

        try {
            if (typeof timestampOverrideParam === 'string') {
                this.timestampOverride = timestampOverrideParam.replace(/\s/g, '');
                console.warn('Historical weather prompt timestamp override:', this.timestampOverride);
            }

            if (typeof locationOverrideParam === 'string') {
                const isLatLng = locationOverrideParam.replace(/\s/g, '').match(/[^\d.,-]/g) === null;
                if (isLatLng) {
                    const [lng, lat] = locationOverrideParam.split(',').map(parseFloat);
                    const response = await reverseGeocode([lng, lat], { limit: 1 });
                    this.locationOverride = response.features?.[0] ?? null;
                } else {
                    const response = await geocode(locationOverrideParam, { limit: 1 });
                    this.locationOverride = response.features?.[0] ?? null;
                }
                if (this.locationOverride) {
                    console.warn('Historical weather prompt location override:', this.locationOverride);
                }
            }
        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        async fetchHistoricalWeatherAnalysis() {
            this.analysis = null;

            let attempts = 3;
            while (attempts !== 0) {
                try {
                    attempts -= 1;
                    const response = await this.actuallyFetchHistoricalWeatherAnalysis();
                    this.analysis = tidyWeatherAnalysis(response);
                    break;
                } catch (error) {
                    console.warn(`Historical weather API call failed, ${attempts} attempts remaining`);
                    if (attempts === 0) {
                        console.error(error);
                    }
                }
            }
        },

        async actuallyFetchHistoricalWeatherAnalysis(): Promise<WeatherAnalyticsResponse> {
            if (!this.apiEndpoint) {
                throw new Error('Tried to fetch historical weather data without enough information.');
            }

            const response = await fetch(this.apiEndpoint);

            if (!response.ok) {
                throw new Error(`Historical weather API call failed: ${response.statusText}`);
            }

            return await response.json();
        },

        handleDismissClick() {
            this.dismiss();
            trackHistoricalWeatherPromptDismiss(this.analysis);
        },

        handleCtaClick() {
            this.dismiss();
            trackHistoricalWeatherPromptClickThrough(this.analysis);
        },

        dismiss() {
            this.analysis = null;
            this.$store.dispatch('updateSettings', { historicalWeatherPromptDismissed: new Date().toISOString() });
        },
    },
});
