import App from '@/App.vue';
import { MIXPANEL_TOKEN } from '@/config';
import { i18n } from '@/i18n';
import vuetify from '@/plugins/Vuetify';
import router from '@/router';
import store from '@/store';
import { App as CapacitorApp } from '@capacitor/app';
import { Device } from '@capacitor/device';
import mixpanel from 'mixpanel-browser';
import Vue from 'vue';
import addCheckToInstallBackgroundUpdates from './boot/background-updates';
import addCheckForExpiredAuthToken from './boot/check-expired-auth-tokens';
import setUpExperiments from './boot/set-up-experiments';
import setUpPrismic from './boot/set-up-prismic';
import setUpSentry from './boot/set-up-sentry';
import setUpVue from './boot/set-up-vue';
import setUpVueExtras from './boot/set-up-vue-extras';
import { trackEnvironment, trackExperimentGroups, trackLocale } from './tracking';
import { init as MoEngageInit } from './util.moengage';

import '@fontsource-variable/inter';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import './style/main.css';
import './style/platform.css';
import './style/util.css';
import './style/variables.css';
import './style/vuetify.css';

async function main() {
    mixpanel.init(MIXPANEL_TOKEN, {
        debug: false,
        cross_subdomain_cookie: false,
        record_sessions_percent: 100,
        record_block_selector: '',
        record_mask_text_selector: 'input[type="password"]',
    });

    MoEngageInit();

    if (process.env.NODE_ENV === 'production') {
        setUpSentry();
    }

    addCheckToInstallBackgroundUpdates();
    addCheckForExpiredAuthToken();
    setUpVue();
    setUpExperiments();
    setUpVueExtras();
    setUpPrismic();

    Device.getInfo().then(async deviceInfo => {
        const appInfo = await CapacitorApp.getInfo().catch(() => null);
        trackEnvironment(deviceInfo, appInfo ?? undefined);
    });

    trackExperimentGroups();

    store.dispatch('checkIfSignedIn', { firstTime: true });

    new Vue({
        router,
        i18n,
        store,
        vuetify,
        watch: {
            '$i18n.locale': function(locale) {
                localStorage.userLang = locale;
                trackLocale(locale, false);
                document.documentElement.lang = locale;
            },

            '$store.state.account.currentUser.userSettings.preferredSystemOfMeasure': {
                immediate: true,
                handler(preferredSystemOfMeasure) {
                    if (preferredSystemOfMeasure) {
                        store.state.apiClient.defaults.headers['X-Preferred-System-Of-Measure'] = preferredSystemOfMeasure;
                        localStorage.preferredSystemOfMeasure = preferredSystemOfMeasure
                    } else {
                        // Leave whatever the last value was!
                    }
                },
            }
        },

        mounted() {
            CapacitorApp.getLaunchUrl().then(launchUrl => {
                if (launchUrl?.url) {
                    const url = new URL(launchUrl.url);
                    const urlEnd = url.href.slice(url.origin.length);
                    if (urlEnd) {
                        // When the app is first loaded, things need some time to settle, I guess.
                        setTimeout(() => router.replace(urlEnd), 1500);
                    }
                }
            });

            // The apps can handle links to https://www.iseechange.org/*
            // Setup guide at https://capacitorjs.com/docs/guides/deep-links
            CapacitorApp.addListener('appUrlOpen', event => {
                const url = new URL(event.url);
                const urlEnd = url.href.slice(url.origin.length);
                if (urlEnd) {
                    router.push(urlEnd);
                }
            });
        },

        render: (h) => h(App),
    }).$mount('#app');

    // There used to be a service worker installed that wasn't removed properly.
    // This'll clear it out on the next load.
    const swRegistrations = await navigator.serviceWorker?.getRegistrations();
    swRegistrations?.forEach(registration => registration.unregister());
}

main();
