
import RouteNames from '@/router/names';
import Vue from '@/vueTyped';
import PrimaryLogo from './logo/Primary.vue';

const ON_FIRST_LOADED_PAGE = 'ON_FIRST_LOADED_PAGE';
let onFirstLoadedPage = true;
history.replaceState({ [ON_FIRST_LOADED_PAGE]: onFirstLoadedPage }, '');

export default Vue.extend({
    components: {
        PrimaryLogo,
    },

    props: {
        iconOnly: Boolean,
    },

    data() {
        return {
            onFirstLoadedPage,
        };
    },

    computed: {
        iconGoesHome(): boolean {
            const hasBaseRoute = this.$route.meta?.baseRoute;
            return this.onFirstLoadedPage || !hasBaseRoute;
        },

        homeRoute() {
            return { name: RouteNames.HOME };
        },
    },

    watch: {
        $route() {
            try {
                this.onFirstLoadedPage = history.state[ON_FIRST_LOADED_PAGE];
            } catch (error) {
                this.onFirstLoadedPage = false;
            }
        },

        onFirstLoadedPage() {
            onFirstLoadedPage = this.onFirstLoadedPage;
        },
    },

    methods: {
        handleClick() {
            this.$router.back();
        },
    },

});
