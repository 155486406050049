
import Vue from '@/vueTyped';
import MarkdownOutput from '../MarkdownOutput.vue';
import PromptBanner from '@/components/PromptBanner.vue';
import { Survey, SurveyRequest } from '@/types';
import { Location } from 'vue-router';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                hasRequest: 'You’ve been requested to complete a survey.',
                accept: 'Take the survey',
                decline: 'Decline',
            },
        },
    },

    components: {
        MarkdownOutput,
        PromptBanner,
    },

    computed: {
        routeLocation(): Location {
            return this.$router.resolve(this.$route.fullPath, this.$route).location;
        },

        firstRequest(): SurveyRequest | undefined {
            return this.$store.getters.surveyRequests?.[0];
        },

        survey(): Survey | null {
            return this.$store.getters.survey(this.firstRequest?.survey_id) ?? null;
        },

        visible(): boolean {
            return this.firstRequest !== undefined && !this.$route.meta?.hideSurveyRequestsPrompt;
        },

        mandatory(): boolean {
            return this.firstRequest?.mandatory ?? this.survey?.settings?.defaultMandatory ?? false;
        },
    },

    watch: {
        '$store.state.account.currentUser.id': {
            immediate: true,
            handler() {
                this.$store.dispatch('fetchSurveyRequests');
            },
        },

        'firstRequest.survey_id': {
            immediate: true,
            handler(surveyId) {
                if (surveyId) {
                    this.$store.dispatch('fetchSurvey', surveyId);
                }
            },
        },
    },

    methods: {
        declineRequest(id: SurveyRequest['id']) {
            this.$store.dispatch('dismissSurveyRequest', id);
        },
    },
});
