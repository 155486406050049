import router from '@/router';
import { PrismicDocument } from './types';

export default async function(document: PrismicDocument): Promise<string> {
    if (document.type === 'site_content_group') {
        return router.resolve({
            name: 'cms_content_page',
            params: { collection: String(document.uid) },
        }).href;
    } else if (document.type === 'site_content') {
        return router.resolve({
            name: 'cms_content_page',
            params: { page: String(document.uid) },
        }).href;
    } else {
        return '/';
    }
}
