import Vue from 'vue';
import Vuetify, {
    VAlert,
    VApp,
    VAutocomplete,
    VAvatar,
    VBadge,
    VBottomNavigation,
    VBottomSheet,
    VBtn,
    VBtnToggle,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VCarousel,
    VProgressCircular,
    VCarouselItem,
    VCheckbox,
    VChip,
    VChipGroup,
    VContainer,
    VDatePicker,
    VDialog,
    VDivider,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VFlex,
    VRow,
    VLazy,
    VCol,
    VForm,
    VIcon,
    VImg,
    VLayout,
    VList,
    VListItem,
    VListItemTitle,
    VListItemSubtitle,
    VListItemContent,
    VListItemGroup,
    VListItemAction,
    VMenu,
    VNavigationDrawer,
    VRadio,
    VRadioGroup,
    VSelect,
    VStepper,
    VStepperContent,
    VStepperStep,
    VSnackbar,
    VSlider,
    VSkeletonLoader,
    VSubheader,
    VSpacer,
    VSwitch,
    VTab,
    VTabs,
    VTabItem,
    VTextarea,
    VTextField,
    VTimePicker,
    VAppBar,
    VToolbar,
    VToolbarItems,
    VAppBarNavIcon,
    VToolbarTitle,
    VOverflowBtn,
} from 'vuetify/lib';
import Ripple from 'vuetify/lib/directives/ripple';
import restoreFocusMixin from './restoreFocusMixin';

VDialog.mixin(restoreFocusMixin);

Vue.use(Vuetify, {
    components: {
        VAlert,
        VApp,
        VAutocomplete,
        VAvatar,
        VBadge,
        VBottomNavigation,
        VBottomSheet,
        VBtn,
        VBtnToggle,
        VCard,
        VCardActions,
        VCardText,
        VCardTitle,
        VCarousel,
        VCarouselItem,
        VProgressCircular,
        VCheckbox,
        VChip,
        VChipGroup,
        VContainer,
        VDatePicker,
        VDialog,
        VDivider,
        VExpansionPanel,
        VExpansionPanels,
        VExpansionPanelHeader,
        VExpansionPanelContent,
        VFlex,
        VRow,
        VLazy,
        VCol,
        VForm,
        VIcon,
        VImg,
        VLayout,
        VList,
        VListItem,
        VListItemTitle,
        VListItemSubtitle,
        VListItemContent,
        VListItemGroup,
        VListItemAction,
        VMenu,
        VNavigationDrawer,
        VRadio,
        VRadioGroup,
        VSlider,
        VSkeletonLoader,
        VSelect,
        VStepper,
        VStepperContent,
        VStepperStep,
        VSnackbar,
        VSubheader,
        VSpacer,
        VSwitch,
        VTab,
        VTabItem,
        VTabs,
        VTextarea,
        VTextField,
        VTimePicker,
        VAppBar,
        VToolbar,
        VToolbarItems,
        VAppBarNavIcon,
        VToolbarTitle,
        VOverflowBtn,
    },
    directive: {
        Ripple,
    },
});

import FacebookIcon from '@/components/icons/FacebookIcon.vue';
import InstagramIcon from '@/components/icons/InstagramIcon.vue';
import LinkedInIcon from '@/components/icons/LinkedInIcon.vue';
import StoryIcon from '@/components/icons/StoryIcon.vue';
import TelegramIcon from '@/components/icons/TelegramIcon.vue';
import TumblrIcon from '@/components/icons/TumblrIcon.vue';
import TwitterIcon from '@/components/icons/TwitterIcon.vue';

const opts = {
    icons: {
        iconfont: 'md', // default - only for display purposes
        values: {
            facebook: { component: FacebookIcon, },
            instagram: { component: InstagramIcon, },
            linkedin: { component: LinkedInIcon, },
            story: { component: StoryIcon, },
            telegram: { component: TelegramIcon, },
            tumblr: { component: TumblrIcon, },
            twitter: { component: TwitterIcon, },
        }
    },
    theme: {
        themes: {
            light: {
                primary: '#0172DA',
                'primary-light': '#20A0EC',
                secondary: '#062B62',
                'secondary-light': '#154388',
                accent: '#54A7A8',
                error: '#e0695e',
                info: '#54A7A8',
                success: '#72bb2e',
                warning: '#ffce00'
            }
        }
    },
};

import '@/style/vuetify.scss';
export default new Vuetify(opts);
