
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import { CurrentUser, Survey, SurveyQuestion } from '@/types';
import Vue from '@/vueTyped';
import localforage from 'localforage';
import ProgressBar from '../ProgressBar.vue';

type AnswerErrorState = 'required';

type AnswerTransformedForApi = {
    surveyQuestionId: SurveyQuestion['id'];
    answer: TextAnswerValue | RadioAnswerValue | CheckboxAnswerValue;
};

type TextAnswerValue = {
    value: string;
};

type RadioAnswerValue = {
    value: unknown;
    input?: string;
};

type CheckboxAnswerValue = {
    values: {
        value: unknown;
        input?: string;
    }[];
};

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                canSkip: 'It looks like you haven’t selected an answer. If this was intentional, click “Next”.',
                answerRequired: 'An answer is required',
                errors: {
                    required: 'A response to this question is required.',
                },
                submission: {
                    failure: 'An error occurred while submitting your response, please try again.',
                    success: 'Thanks, we’ve received your response!',
                },
            },

            es: {
                canSkip: 'Parece que no ha seleccionado ninguna respuesta. Si ha sido intencionado, haga clic en “Siguiente”.',
                answerRequired: 'Se requiere una respuesta',
                errors: {
                    required: 'Se requiere una respuesta a esta pregunta.',
                },
                submission: {
                    failure: 'Se ha producido un error al enviar su respuesta, inténtelo de nuevo.',
                    success: 'Gracias, ¡hemos recibido su respuesta!',
                },
            },
        },
    },

    components: {
        MarkdownOutput,
        TransitionExpand,
        ProgressBar,
    },

    props: {
        surveyId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            currentQuestionIndex: -1,
            answers: {} as Record<SurveyQuestion['id'], unknown>,
            errors: {} as Record<SurveyQuestion['id'], AnswerErrorState | null>,
            submissionInProgress: false,
            submissionError: null as Error | null,
            submitted: false,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        survey(): Survey | null {
            return this.$store.getters.survey(this.surveyId) ?? null;
        },

        storedAnswersKey(): string | null {
            const currentUserId = this.currentUser?.id;
            const surveyId = this.survey?.id;
            const safeToStoreAnswers = !this.survey?.settings?.containsSensitiveData;
            if (currentUserId && surveyId && safeToStoreAnswers) {
                return `survey-answers-${currentUserId}-${surveyId}`;
            } else {
                return null;
            }
        },

        visibleQuestions(): SurveyQuestion[] {
            if (!this.survey || !this.survey.surveyQuestions) {
                return [];
            }

            const orderedQuestions = [...this.survey.surveyQuestions].sort((q1, q2) => {
                if (typeof q1.order === 'number' && typeof q2.order === 'number') {
                    return q1.order < q2.order ? -1 : q1.order > q2.order ? +1 : 0;
                } else {
                    return 0;
                }
            });

            return orderedQuestions.filter(this.questionIsVisible);
        },

        currentQuestion(): SurveyQuestion {
            return this.visibleQuestions[this.currentQuestionIndex];
        },

        transformedAnswers(): AnswerTransformedForApi[] {
            try {
                return this.visibleQuestions.map((question) => {
                    if (question.question_type === 'text') {
                        return {
                            surveyQuestionId: question.id,
                            answer: { value: this.answers[question.id] },
                        };
                    }

                    if (question.question_type === 'radio') {
                        const answerOption = question.options?.find(option => option.value === this.answers[question.id]);

                        return {
                            surveyQuestionId: question.id,
                            answer: {
                                value: this.answers[question.id],
                                input: answerOption?.input ? String(this.answers[`${question.id}:${answerOption.value}`] || '') : undefined,
                            },
                        };
                    }

                    if (question.question_type === 'checkbox') {
                        return {
                            surveyQuestionId: question.id,
                            answer: {
                                values: ((this.answers[question.id] ?? []) as string[]).map(optionValue => {
                                    const answerOption = question.options?.find(option => option.value === optionValue);
                                    return {
                                        value: optionValue,
                                        input: answerOption?.input ? String(this.answers[`${question.id}:${optionValue}`] || '') : undefined,
                                    };
                                }),
                            },
                        };
                    }

                    // TODO: We should never get here, but TypeScript doesn't recognize that.
                    throw new Error(`transformedAnswers not implemented for question of type ${question.question_type}`);
                });
            } catch (error) {
                console.error(error);
                return [];
            }
        },
    },

    watch: {
        survey: {
            immediate: true,
            async handler(survey) {
                this.currentQuestionIndex = 0;

                if (this.storedAnswersKey && !this.survey?.settings?.dontPersistAnswers) {
                    try {
                        const draftAnswers = await localforage.getItem(this.storedAnswersKey);
                        this.$set(this, 'answers', draftAnswers ?? {});
                    } catch (error) {
                        console.error(`Error reading survey answers "${this.storedAnswersKey}"`, error);
                        this.$set(this, 'answers', {});
                    }
                } else {
                    this.$set(this, 'answers', {});
                }

                for (const question of survey.surveyQuestions) {
                    if (!(question.id in this.answers)) {
                        this.$set(this.answers, question.id, this.makeDefaultAnswer(question));
                    }
                }

                this.$set(this, 'errors', {});
            },
        },

        answers: {
            deep: true,
            handler() {
                if (this.storedAnswersKey && !this.survey?.settings?.dontPersistAnswers) {
                    try {
                        localforage.setItem(this.storedAnswersKey, this.answers);
                    } catch (error) {
                        console.error(`Error storing survey answers "${this.storedAnswersKey}"`, error);
                    }
                }
            },
        },
    },

    methods: {
        makeDefaultAnswer(question: SurveyQuestion): unknown {
            switch (question.question_type) {
                case 'text': return '';
                case 'radio': return null;
                case 'checkbox': return [];
            }
            // @ts-expect-error: The following will be unreachable as long as we define a default answer for each type.
            console.error(`makeDefaultAnswer not implemented for question_type "${question.question_type}"`);
        },

        questionIsVisible(question: SurveyQuestion): boolean {
            const surveyQuestions = this.survey?.surveyQuestions ?? [];
            if (question.on_condition?.[0] === 'ANSWERED_AFFIRMATIVE') {
                const parentQuestionIds = question.on_condition.slice(1);
                const parentQuestions = parentQuestionIds.flatMap(questionId => surveyQuestions.find(question => question.id === questionId) ?? []);
                const visibleParentQuestions = parentQuestions.filter(this.questionIsVisible);
                return visibleParentQuestions.every(parentQuestion => {
                    const answer = this.answers[parentQuestion.id];
                    return Array.isArray(answer) ? answer.length !== 0 : Boolean(answer);
                });
            } else if (question.on_condition?.[0] === 'ANSWER_EQUALS') {
                const [targetQuestionId, ...acceptableTargetAnswerValues] = question.on_condition.slice(1);
                if (!targetQuestionId) {
                    console.error(`Question with ID ${targetQuestionId} doesn't exist, but it's used in the condition for ${question.id}`);
                }
                const targetQuestion = surveyQuestions.find(question => question.id === targetQuestionId);
                return Boolean(
                    targetQuestion
                    && this.questionIsVisible(targetQuestion)
                    && acceptableTargetAnswerValues.includes(this.answers[targetQuestion.id])
                );
            } else if (question.on_condition?.[0] === 'ONCE_PER_DAY') {
                const QUERY_PARAM = 'prevRequestCreatedAt';
                if (typeof this.$route.query[QUERY_PARAM] === 'string') {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const previousSubmission = new Date(this.$route.query[QUERY_PARAM]);
                    return previousSubmission < today;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },

        async handleSubmission() {
            if (!this.survey) throw new Error('`handleSubmission` called with no survey');

            try {
                this.submissionError = null;
                this.submissionInProgress = true;

                this.confirmResponseValidity();
                const isValid = Object.values(this.errors).filter(Boolean).length === 0;

                if (isValid) {
                    const { error } = await this.$store.dispatch('respondToSurvey', {
                        surveyId: this.survey.id,
                        answers: this.transformedAnswers,
                    });

                    if (error) {
                        throw error;
                    } else {
                        this.submitted = true;
                        if (this.storedAnswersKey) {
                            try {
                                localforage.removeItem(this.storedAnswersKey);
                            } catch (error) {
                                console.error(`Error removing survey answers "${this.storedAnswersKey}"`, error);
                            }
                        }
                    }
                }
            } catch (error) {
                this.submissionError = error;
                this.$store.dispatch('alertUser', { type: 'error', message: this.$t('submission.failure') });
            } finally {
                this.submissionInProgress = false;
            }
        },

        questionIsAnswered(question: SurveyQuestion): boolean {
            const transformedAnswer = this.transformedAnswers.find(answer => answer.surveyQuestionId === question.id);
            if (!transformedAnswer) {
                return false;
            } else if (question.question_type === 'text') {
                return String(this.answers[question.id] || '').trim() !== '';
            } else if (question.question_type === 'radio') {
                const selectedOption = question.options?.find(option => option.value === this.answers[question.id]);
                if (selectedOption?.input) {
                    return String((transformedAnswer.answer as RadioAnswerValue).input || '').trim() !== '';
                } else {
                    return this.answers[question.id] !== null;
                }
            } else if (question.question_type === 'checkbox') {
                const values = (transformedAnswer.answer as CheckboxAnswerValue).values;
                return Array.isArray(values) && values.length > 0 && values.every(answer => {
                    const relatedOption = question.options?.find(option => option.value === answer.value);
                    if (relatedOption?.input) {
                        return String(answer.input || '').trim() !== '';
                    } else {
                        return true;
                    }
                });
            } else {
                return true;
            }
        },

        isAnswerValid(question: SurveyQuestion, updateState: boolean): boolean {
            const requiredButNotAnswered = question.is_required && !this.questionIsAnswered(question);

            if (updateState) {
                this.errors[question.id] = requiredButNotAnswered ? 'required' : null;
            }

            return !requiredButNotAnswered;
        },

        confirmResponseValidity() {
            if (!this.survey) throw new Error('`confirmResponseValidity` called with no survey');

            for (const question of this.visibleQuestions) {
                this.isAnswerValid(question, true);
            }
        },
    },
});
